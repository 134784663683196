const adminUid = 'xHDHptO2gbUSwVZXOpBYC0jtse72'
const items = [
  {
    category: 'premiums',
    name: 'gold-cash-10',
    genetic: 'Oreoz 1.0 x Pure Michigan ( Oreoz 1.0 x Mendobreath)',
    breeder: '3rd Coast Genetics',
    title: 'Gold Cash #10',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #10',
    flowerTime: '8 Wk/ Late Sept',
    flavor: 'Sweet/ Candy',
    desc: 'Average yielder, super frosty ',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'oreoz-1.0',
    genetic: 'Cookies n Cream x Secret Weapon',
    breeder: '3rd Coast Genetics',
    title: 'Oreoz 1.0',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'black-ice',
    genetic: 'Mac #1 x Oreoz 1.0',
    breeder: '3rd Coast Genetics',
    title: 'Black Ice',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'black-truffle',
    genetic: 'Mac #1 x Oreoz 1.0',
    breeder: 'White Truffle x Pure Michgan',
    title: 'Black Truffle',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'dantes-inferno-black-velvet',
    genetic: '(Oreoz x Devil Driver)',
    breeder: 'Clearwater Genetics',
    title: 'Dantes Inferno/ Black Velvet ',
    price: '1000',
    quantity: '1',
    pheno: "Chef's Cut",
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'the-menthol-s1-4',
    genetic: 'Gelato 45 x (White Diesel x (High Octane x Jet Fuel)',
    breeder: 'Compound Genetics',
    title: 'The Menthol S1 #4',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #4',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'the-menthol-s1-5',
    genetic: 'Gelato 45 x (White Diesel x (High Octane x Jet Fuel)',
    breeder: 'Compound Genetics',
    title: 'The Menthol S1 #5',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #5',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'the-menthol-s1-11',
    genetic: 'Gelato 45 x (White Diesel x (High Octane x Jet Fuel)',
    breeder: 'Compound Genetics',
    title: 'The Menthol S1 #11',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #11',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'the-menthol-s1-15',
    genetic: 'Gelato 45 x (White Diesel x (High Octane x Jet Fuel)',
    breeder: 'Compound Genetics',
    title: 'The Menthol S1 #15',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #15',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'khalifa-mintz-9',
    genetic: 'Khalifa Kush x The Menthol',
    breeder: 'Compound Genetics',
    title: 'Khalifa Mintz #9',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #9',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'grapple-pie-5',
    genetic: 'Apple Fritter x Grape Gasoline (Grape pie x jet fuel gelato)',
    breeder: 'Compound Genetics',
    title: 'Grapple Pie #5',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #5',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'grapple-pie-12',
    genetic: 'Apple Fritter x Grape Gasoline (Grape pie x jet fuel gelato)',
    breeder: 'Compound Genetics',
    title: 'Grapple Pie #12',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #12',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'violet-fog-22',
    genetic: 'Khalifa Mintz x Grape Gasoline (Grape pie x jet fuel gelato)',
    breeder: 'Compound Genetics',
    title: 'Violet Fog #22',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #22',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'violet-fog-24',
    genetic: 'Khalifa Mintz x Grape Gasoline (Grape pie x jet fuel gelato)',
    breeder: 'Compound Genetics',
    title: 'Violet Fog #24',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #24',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'pink-grapes-8',
    genetic: 'Pink Runtz x Grape Gasoline (Grape pie x jet fuel gelato)',
    breeder: 'Compound Genetics',
    title: 'Pink Grapes #8',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #8',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'pink-grapes-9',
    genetic: 'Pink Runtz x Grape Gasoline (Grape pie x jet fuel gelato)',
    breeder: 'Compound Genetics',
    title: 'Pink Grapes #9',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #9',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'potato-runtz-2',
    genetic: 'White Runtz x Pink Runtz',
    breeder: 'Compound Genetics',
    title: 'Potato Runtz #2',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #2',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'potato-runtz-7',
    genetic: 'White Runtz x Pink Runtz',
    breeder: 'Compound Genetics',
    title: 'Potato Runtz #7',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #7',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'jokerz-7',
    genetic: 'White Runtz x Jet fuel gelato',
    breeder: 'Compound Genetics',
    title: 'Jokerz #7',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #7',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'jokerz-10',
    genetic: 'White Runtz x Jet fuel gelato',
    breeder: 'Compound Genetics',
    title: 'Jokerz #10',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #10',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'jokerz-11',
    genetic: 'White Runtz x Jet fuel gelato',
    breeder: 'Compound Genetics',
    title: 'Jokerz #11',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #11',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'baby-yoda',
    genetic: 'Khalifa Kush x The Menthol',
    breeder: 'Compound Genetics',
    title: 'Baby Yoda',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'rainbow-sherbert-11',
    genetic: '(Pink Guava x OZK) x Sunset Sherbert',
    breeder: 'Doja Pak/Wizard Trees/Deep East',
    title: 'Rainbow Sherbert-11',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'studio-54',
    genetic: '(Pink Guava x OZK) x Sunset Sherbert',
    breeder: 'Doja Pak/Wizard Trees/Deep East',
    title: 'Studio 54',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'zzoap',
    genetic: '(Pink Guava x OZK) x RS-21 F2',
    breeder: 'Doja Pak/Wizard Trees/Deep East',
    title: 'ZZoap',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'gary-payton',
    genetic: '(The Y x Snowman)',
    breeder: 'Cookies',
    title: 'Gary Payton',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'cheetah-piss',
    genetic: 'Lemonade x Gelato 42 x London Pound Cake',
    breeder: 'Cookies',
    title: 'Cheetah Piss',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'bernscotti',
    genetic: 'Biscotti x Jealousy F2',
    breeder: 'Cookies',
    title: 'Bernscotti',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'yahemi',
    genetic: 'Melonatta x Project 4516',
    breeder: 'Grandiflora',
    title: 'Yahemi',
    price: '1000',
    quantity: '1',
    pheno: 'Clone/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'dripz',
    genetic: 'Gushers x Project 4516',
    breeder: 'Grandiflora',
    title: 'Dripz',
    price: '1000',
    quantity: '1',
    pheno: 'BCS Cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'medellin',
    genetic: 'Wedding Cake x Chem D',
    breeder: 'Lemonade',
    title: 'Medellin',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'georgia-apple-pie-2',
    genetic: 'Apple Fritter x Georgia Pie',
    breeder: 'Raw Genetics',
    title: 'Georgia Apple Pie #2',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #2',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'georgia-apple-pie-8',
    genetic: 'Apple Fritter x Georgia Pie',
    breeder: 'Raw Genetics',
    title: 'Georgia Apple Pie #8',
    price: '1000',
    quantity: '1',
    pheno: 'Haxa Lab #8',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'cap-junky',
    genetic: 'Alien cookie x Kushmints',
    breeder: 'Seed Junky Genetics',
    title: 'Cap Junky',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'super-runtz',
    genetic: '(Biscotti x Sherb) x Jealousy',
    breeder: 'Seed Junky Genetics',
    title: 'Super Runtz',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'cap-junky',
    genetic: 'Alien cookie x Kushmints',
    breeder: 'Seed Junky Genetics',
    title: 'Cap Junky',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'premiums',
    name: 'purple-octane-jealousy',
    genetic: 'Purple Octane (Biscotti S1 x Sherb Bx) x Jealousy F2) x Jealousy',
    breeder: 'Seed Junky Genetics',
    title: 'Purple Octane x Jealousy',
    price: '1000',
    quantity: '1',
    pheno: 'Clone only/ breeder cut',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },

  // Clones
  {
    category: 'clones',
    name: 'gushers',
    genetic: 'Triangle kush x Florida OG',
    breeder: 'Connected',
    title: 'Gushers',
    price: '15',
    quantity: '1',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'clones',
    name: 'jealousy-f3',
    genetic: 'Gelato 41 x Sunset Sherbert',
    breeder: 'Seed Junky Genetics',
    title: 'Jealousy F3',
    price: '15',
    quantity: '1',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'clones',
    name: 'banana-jelly',
    genetic: 'Banana Cream Cake x Jealousy',
    breeder: 'Seed Junky Genetics',
    title: 'Banana Jelly',
    price: '15',
    quantity: '1',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'clones',
    name: 'rippz',
    genetic: 'Biscotti x Sunset Sherbert',
    breeder: 'Seed Junky Genetics',
    title: 'Rippz',
    price: '15',
    quantity: '1',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'clones',
    name: 'ice-cream-cake-kush-mints',
    genetic: 'Ice Cream Cake x Kush Mints',
    breeder: 'Seed Junky Genetics',
    title: 'Ice Cream Cake x Kush Mints',
    price: '15',
    quantity: '1',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'sno-conez',
    genetic: 'Candyland x Oreoz',
    breeder: '3rd Coast Genetics',
    title: 'Sno Conez',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'taglaongz',
    genetic: 'Oreoz 1.0 x PBB',
    breeder: '3rd Coast Genetics',
    title: 'Taglaongz',
    price: 'TBD',
    quantity: '-',
    pheno: '#2, #4, #6, #10',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'alien-mints-planet-dosi',
    genetic: 'Alien Mints x Planet Dosi',
    breeder: 'Alien Labs',
    title: 'Alien Mints x Planet Dosi',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'baklava-biscotti',
    genetic: 'Baklava x Biscotti',
    breeder: 'Alien Labs',
    title: 'Baklava x Biscotti',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~4, #6, #7, #9',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'melonade-planet-dosi',
    genetic: 'Melonade x Planet Dosi',
    breeder: 'Alien Labs',
    title: 'Melonade x Planet Dosi',
    price: 'TBD',
    quantity: '-',
    pheno: '#2, #4, #8',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'sherbacio-biscotti',
    genetic: 'Sherbacio x Biscotti',
    breeder: 'Alien Labs',
    title: 'Sherbacio x Biscotti',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'cheetah-piss-gushers',
    genetic: 'Cheetah Piss x Gushers',
    breeder: 'Cannarado Genetics',
    title: 'Cheetah Piss x Gushers',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'gary-payton-gushers',
    genetic: 'Gary Payton x Gushers',
    breeder: 'Cannarado Genetics',
    title: 'Gary Payton x Gushers',
    price: 'TBD',
    quantity: '-',
    pheno: '#2, #3, #5',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'cereal-milk-fish-scale',
    genetic: 'Cereal Milk x Fish Scale',
    breeder: 'Cookies',
    title: 'Cereal Milk x Fish Scale',
    price: 'TBD',
    quantity: '-',
    pheno: '#1',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'gary-payton-fish-scale',
    genetic: 'Gary Payton x Fish Scale',
    breeder: 'Cookies',
    title: 'Gary Payton x Fish Scale',
    price: 'TBD',
    quantity: '-',
    pheno: '#1, #6, #8~11',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'cereal-milk-fish-scale',
    genetic: 'Cereal Milk x Fish Scale',
    breeder: 'Cookies',
    title: 'Cereal Milk x Fish Scale',
    price: 'TBD',
    quantity: '-',
    pheno: '#1',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'fish-scale-s1',
    genetic: '-',
    breeder: 'Cookies',
    title: 'Fish Scale S1',
    price: 'TBD',
    quantity: '-',
    pheno: '#1, #2, #4~21',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'gary-payton-apples-bananas',
    genetic: 'Gary Payton x Apples & Bananas',
    breeder: 'Cookies',
    title: 'Gary Payton x Apples & Bananas',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~9, #11~21',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'gary-payton-apples-bananas',
    genetic: 'Gary Payton x Apples & Bananas',
    breeder: 'Cookies',
    title: 'Gary Payton x Apples & Bananas',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~9, #11~21',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'gary-payton-jealousy',
    genetic: 'Gary Payton x Jealousy',
    breeder: 'Cookies',
    title: 'Gary Payton x Jealousy',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~12',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'candy-pave',
    genetic: 'Eye Candy x Pave',
    breeder: 'Compound Genetics',
    title: 'Candy Pave',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~12',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'goofiez',
    genetic: 'Jokerz x Apples & Bananas',
    breeder: 'Compound Genetics',
    title: 'Goofiez',
    price: 'TBD',
    quantity: '-',
    pheno: '#1, #2, #4, #6~8',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'facade',
    genetic: 'Eye Candy x Apples & Bananas',
    breeder: 'Compound Genetics',
    title: 'Facade',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~4, #6~8, #10',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'limoncello-28-jet-fuel-gelato',
    genetic: 'Limoncello 28 x Jet Fuel Gelato',
    breeder: 'Compound Genetics',
    title: 'Limoncello 28 x Jet Fuel Gelato',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'purple-churro',
    genetic: 'Cinnamon Horchata x Apples & Bananas',
    breeder: 'Compound Genetics',
    title: 'Purple Churro',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'tiger-moon',
    genetic: 'GD Pluto x Apples & Bananas',
    breeder: 'Compound Genetics',
    title: 'Tiger Moon',
    price: 'TBD',
    quantity: '-',
    pheno: '#2, #4',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'white-rainbow',
    genetic: 'Fish Scale x Apples & Bananas',
    breeder: 'Compound Genetics',
    title: 'White Rainbow',
    price: 'TBD',
    quantity: '-',
    pheno: '#2, #4',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'kept-secret',
    genetic: 'Fish Scale x Apples & Bananas',
    breeder: 'Cool Beans Genetics',
    title: 'Kept Secret',
    price: 'TBD',
    quantity: '-',
    pheno: '#3, #4, #6, #7, #10, #11',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'doggy-style',
    genetic: 'Zkittlez x Project 4516',
    breeder: 'Grandiflora',
    title: 'Doggy Style',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'platinum-gushers',
    genetic: 'Gushers x Platinum',
    breeder: 'Inhouse Genetics',
    title: 'Platinum Gushers',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'platinum-oreoz',
    genetic: 'Oreoz x Platinum',
    breeder: 'Inhouse Genetics',
    title: 'Platinum Oreoz',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~5, #8~10, #17',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'rainbow-rocket',
    genetic: 'Rainbow Sherbert-11 x E85',
    breeder: 'Savage Genetics',
    title: 'Rainbow Rocket',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~5, #7~10',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'gas-face',
    genetic: 'Face Mints x (Biscotti x Sherb bx)',
    breeder: 'Seed Junky Genetics',
    title: 'Gas Face',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~13',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'gogurtz',
    genetic: 'Runtz x Cap Junky',
    breeder: 'Seed Junky Genetics',
    title: 'Gogurtz',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~13',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'jealousy-bx1',
    genetic: '-',
    breeder: 'Seed Junky Genetics',
    title: 'Jealousy bx1',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~11',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'jealousy-kush-mint-11',
    genetic: 'Jealousy x Kush Mint #11',
    breeder: 'Seed Junky Genetics',
    title: 'Jealousy x Kush Mint #11',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~23',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'jelly-mints',
    genetic: 'Kush Mints F2 x Jealousy',
    breeder: 'Seed Junky Genetics',
    title: 'Jelly Mints',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~9, #13~21, #23~24',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'juicee-j',
    genetic: 'Cap Junky x Jealousy',
    breeder: 'Seed Junky Genetics',
    title: 'Juicee J',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~9, #11~12, #14, #17~18, #20, #22~24',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'ripped-off-runtz',
    genetic: 'Runtz x Jealousy',
    breeder: 'Seed Junky Genetics',
    title: 'Ripped Off Runtz',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~10, #12',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'super-secret',
    genetic: 'Secret Mints x Jealousy',
    breeder: 'Seed Junky Genetics',
    title: 'Super Secret',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~2, #4~5, #7~12, #14~23',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'purple-push-pop',
    genetic: '(Ice Cream Cake x Serbx1) x Jealousy F2',
    breeder: 'Seed Junky Genetics',
    title: 'Purple Push Pop',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~24',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'purple-octane',
    genetic: 'Rippz x Jealousy F2',
    breeder: 'Seed Junky Genetics',
    title: 'Purple Octane',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~12',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'zealousy',
    genetic: 'Zkittlez x Jealousy x Kush Mints',
    breeder: 'Seed Junky Genetics',
    title: 'Zealousy',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~12',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'apple-jelly',
    genetic: 'Jealousy x Apples n Bananas',
    breeder: 'Star Fire Genetics',
    title: 'Apple Jelly',
    price: 'TBD',
    quantity: '-',
    pheno: '#3, #5, #9, #11, #15~16, #18~19, #20, #22',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'apple-butter',
    genetic: 'Jet Fuel Gelato x Apples n Bananas',
    breeder: 'Star Fire Genetics',
    title: 'Apple Butter',
    price: 'TBD',
    quantity: '-',
    pheno: '#1, #2, #6~11',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'bapez',
    genetic: 'Cheetah Piss x Apples n Bananas',
    breeder: 'Star Fire Genetics',
    title: 'Bapez',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'snapplez',
    genetic: 'Yahemi x Apples n Bananas',
    breeder: 'Star Fire Genetics',
    title: 'Snapplez',
    price: 'TBD',
    quantity: '-',
    pheno: '-',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
  {
    category: 'coming',
    name: 'supreme-oreoz',
    genetic: '-',
    breeder: 'Star Fire Genetics',
    title: 'Supreme Oreoz',
    price: 'TBD',
    quantity: '-',
    pheno: '#1~3, #5~10, #12~14, #16, #22',
    flowerTime: '-',
    flavor: '-',
    desc: '-',
    pid: Date.now(),
    url: 'https://firebasestorage.googleapis.com/v0/b/haxa-16f57.appspot.com/o/1656300267479?alt=media&token=4a8cbf5d-7bea-4980-b0d1-f2c0dfa7e3e9',
  },
]

export { adminUid, items }
